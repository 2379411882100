import React from 'react';
import Helmet from 'react-helmet';
import { Content, Header, Layout, SectionTitle } from '../components';

import SiteConfig from '../../config/SiteConfig';
import PageProps from '../models/PageProps';
import { HeaderItem } from '../components/Header';
import { Link } from 'gatsby';
import styled from 'styled-components';

const ClearP = styled.p`
  clear: left;
`;

const AboutContent = styled(Content)`
  line-height: 1.4rem;

  & p:first-child {
    margin-top: 0;
  }

  a {
    text-decoration: underline;
  }
`;

export default class AboutPage extends React.Component<PageProps> {
  public render() {
    return (
      <Layout>
        <Helmet title={`Contact | ${SiteConfig.siteTitle}`} />
        <Header linkToHomepage={true} selected={HeaderItem.About}>
          <SectionTitle>About me</SectionTitle>
        </Header>
        <AboutContent>
          <p>Hi! I'm one of those computer people. I work as a technology architect at Atlassian responsible for forms in Jira.
            I co-founded <a href="https://thinktilt.com/">ThinkTilt</a> which developed that forms technology
            and was <a href="https://techcrunch.com/2021/04/19/atlassian-acquires-thinktilt/">acquired by
            Atlassian in April 2021</a>. Before that I designed and built <a href="https://www.smartygrants.com.au/">
            SmartyGrants</a> which has grown to be Australia's most popular grants administration system. I've
            also worked for <a href="https://www.ourcommunity.com.au/">Our Community</a> where one of my proudest
            pieces of work was developing <a href="https://www.givenow.com.au/">GiveNow</a>, the lowest-fee
            donations platform in Australia. The opinions I express here are my own.</p>
          <p>Occasionally I like to write about technology or politics, and I often like to take photos. So this website is a place
            for me to collect my photos, writing, opinions, presentations, etc and share them with the world. Photos are synchronised
            with <a href="https://www.instagram.com/instagutch/">Instagram</a>.</p>
          <ClearP><Link to="/">Return to the homepage</Link></ClearP>
        </AboutContent>
      </Layout>
    );
  }
}
